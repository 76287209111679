var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.SPRConfigList.length)?_c('div',[_c('om-table',{staticClass:"spr-table",attrs:{"items":_vm.SPRConfigList,"columns":_vm.columns,"columnClasses":_vm.columnClasses,"allItemsCount":_vm.SPRConfigList.length,"selectable":false,"show-header":true,"paginate":false},scopedSlots:_vm._u([{key:"domain",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(_vm._s(rowData.row.domain))])]}},{key:"prepareStatus",fn:function(ref){
var rowData = ref.rowData;
return [(!rowData.row.prepareStatus.hasError)?[_c('div',{staticClass:"row-1"},[_c('span',[_vm._v(_vm._s(_vm.$t(("SPR.table.states." + (rowData.row.prepareStatus.state)))))]),(rowData.row.prepareStatus.nofProccessed)?[_c('span',{staticClass:"pl-2"},[_vm._v("(")]),_c('span',{staticClass:"pl-1"},[_vm._v(_vm._s(rowData.row.prepareStatus.nofProccessed))]),_c('span',{staticClass:"pl-1"},[_vm._v(")")])]:_vm._e()],2),(rowData.row.prepareStatus.extraInfo)?_c('div',{staticClass:"row-2"},[_c('span',[_vm._v(_vm._s(rowData.row.prepareStatus.extraInfo))])]):_vm._e()]:[_c('div',{staticClass:"row-1"},[_vm._v(_vm._s(_vm.$t('SPR.table.error')))]),_c('div',{staticClass:"row-2"},[_vm._v(_vm._s(rowData.row.prepareStatus.extraInfo))]),_c('div',{staticClass:"row-3 warning"},[_vm._v(_vm._s(rowData.row.prepareStatus.error.message))])]]}},{key:"generationStatus",fn:function(ref){
var rowData = ref.rowData;
return [(!rowData.row.generationStatus.hasError)?[_c('div',{staticClass:"row-1"},[_c('span',[_vm._v(_vm._s(_vm.$t(("SPR.table.states." + (rowData.row.generationStatus.state)))))]),(rowData.row.generationStatus.requestedAmount)?[_c('span',{staticClass:"pl-2"},[_vm._v("(")]),_c('span',{staticClass:"pl-1"},[_vm._v(_vm._s(rowData.row.generationStatus.resultRowCount))]),_c('span',{staticClass:"pl-1"},[_vm._v("/")]),_c('span',{staticClass:"pl-1"},[_vm._v(_vm._s(rowData.row.generationStatus.requestedAmount))]),_c('span',{staticClass:"pl-1"},[_vm._v(")")])]:_vm._e()],2),(rowData.row.generationStatus.extraInfo)?_c('div',{staticClass:"row-2"},[_c('span',[_vm._v(_vm._s(rowData.row.generationStatus.extraInfo))])]):_vm._e()]:[_c('div',{staticClass:"row-1"},[_vm._v(_vm._s(_vm.$t('SPR.table.error')))]),_c('div',{staticClass:"row-2"},[_vm._v(_vm._s(rowData.row.generationStatus.extraInfo))]),_c('div',{staticClass:"row-3 warning"},[_vm._v(_vm._s(rowData.row.generationStatus.error.message))])]]}},{key:"actions",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('om-button',{staticClass:"mr-2",attrs:{"primary":"","small":"","disabled":!rowData.row.isGenerateEnabled,"icon":"bolt-alt","icon-only":""},on:{"click":function($event){return _vm.generateSPR(rowData.row.id)}}}),_c('om-button',{staticClass:"mr-2",attrs:{"secondary":"","small":"","icon":"trash-alt","icon-only":""},on:{"click":function($event){return _vm.deleteSPRConfig(rowData.row.id)}}})],1)]}}],null,false,1512552842)}),_c('delete-modal'),_c('generate-modal')],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
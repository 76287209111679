<template lang="pug">
div(v-if="SPRConfigList.length")
  om-table.spr-table(
    :items="SPRConfigList"
    :columns="columns"
    :columnClasses="columnClasses"
    :allItemsCount="SPRConfigList.length"
    :selectable="false"
    :show-header="true"
    :paginate="false"
  )
    template(slot="domain" slot-scope="{ rowData }")
      span {{ rowData.row.domain }}

    template(slot="prepareStatus" slot-scope="{ rowData }")
      template(v-if="!rowData.row.prepareStatus.hasError")
        .row-1
          span {{ $t(`SPR.table.states.${rowData.row.prepareStatus.state}`) }}
          template(v-if="rowData.row.prepareStatus.nofProccessed")
            span.pl-2 (
            span.pl-1 {{ rowData.row.prepareStatus.nofProccessed }}
            span.pl-1 )
        .row-2(v-if="rowData.row.prepareStatus.extraInfo")
          span {{ rowData.row.prepareStatus.extraInfo }}
      template(v-else)
        .row-1 {{ $t('SPR.table.error') }}
        .row-2 {{ rowData.row.prepareStatus.extraInfo }}
        .row-3.warning {{ rowData.row.prepareStatus.error.message }}

    template(slot="generationStatus" slot-scope="{ rowData }")
      template(v-if="!rowData.row.generationStatus.hasError")
        .row-1
          span {{ $t(`SPR.table.states.${rowData.row.generationStatus.state}`) }}
          template(v-if="rowData.row.generationStatus.requestedAmount")
            span.pl-2 (
            span.pl-1 {{ rowData.row.generationStatus.resultRowCount }}
            span.pl-1 /
            span.pl-1 {{ rowData.row.generationStatus.requestedAmount }}
            span.pl-1 )
        .row-2(v-if="rowData.row.generationStatus.extraInfo")
          span {{ rowData.row.generationStatus.extraInfo }}
      template(v-else)
        .row-1 {{ $t('SPR.table.error') }}
        .row-2 {{ rowData.row.generationStatus.extraInfo }}
        .row-3.warning {{ rowData.row.generationStatus.error.message }}

    template(slot="actions" slot-scope="{ rowData }")
      .d-flex.justify-content-end
        om-button.mr-2(
          primary
          small
          :disabled="!rowData.row.isGenerateEnabled"
          icon="bolt-alt"
          @click="generateSPR(rowData.row.id)"
          icon-only
        )
        om-button.mr-2(
          secondary
          small
          icon="trash-alt"
          @click="deleteSPRConfig(rowData.row.id)"
          icon-only
        )

  delete-modal
  generate-modal
</template>
<script>
  import { mapGetters } from 'vuex';
  import dateFormat from '@/mixins/dateFormat';
  import GET_SPR_CONFIG_LIST from '@/graphql/GetSPRConfigList.gql';

  const SPR_PREPARE_STATES = {
    RAW: 'raw',
    RUNNING: 'running',
    FINISHED: 'finished',
  };

  const DATE_FORMAT = 'LLL';

  export default {
    components: {
      DeleteModal: () => import('@/components/SPR/SPRConfigDeleteModal.vue'),
      GenerateModal: () => import('@/components/SPR/SPRGenerateModal.vue'),
    },
    mixins: [dateFormat],
    data: () => ({
      columns: [
        { header: 'domain', key: 'domain', sortable: false },
        { header: 'description processing', key: 'prepareStatus', sortable: false },
        { header: 'recommendation generating', key: 'generationStatus', sortable: false },
        { header: 'actions', key: 'actions', sortable: false },
      ],
      columnClasses: {
        domain: 'text-left',
        prepareStatus: 'text-left',
        generationStatus: 'text-left',
        actions: 'text-right',
      },
      rawSPRConfigList: [],
    }),
    computed: {
      ...mapGetters(['domains']),
      SPRConfigList() {
        return this.rawSPRConfigList.map((SPRConfig) => ({
          id: SPRConfig._id,
          domain: this.findDomainName(SPRConfig.domainId),
          prepareStatus: this.buildPrepareStatusData(SPRConfig),
          generationStatus: this.buildGenerationStatusData(SPRConfig),
          isGenerateEnabled: this.isGenerateEnabled(SPRConfig),
        }));
      },
    },
    mounted() {
      this.fetchSPRConfigList();
      this.$bus.$on('reloadSPRConfigList', () => {
        this.fetchSPRConfigList();
      });
    },
    beforeDestroy() {
      this.$bus.$off('reloadSPRConfigList');
    },
    methods: {
      findDomainName(domainId) {
        const domain = this.domains.find((domain) => domain._id.toString() === domainId.toString());
        return domain?.domain;
      },
      async fetchSPRConfigList() {
        let success;
        try {
          const response = await this.$apollo.query({
            query: GET_SPR_CONFIG_LIST,
          });
          success = !!response?.data?.result?.success;

          if (success) {
            this.rawSPRConfigList = response?.data?.result?.list || [];
          }

          if (!success && response?.errors?.length) {
            console.error('Error during get SPR config list', { errors: response.errors });
          }
        } catch (err) {
          console.error('Caugth error during  get SPR config list', {
            msg: err.message,
            stack: err.stack,
          });
        }
      },
      buildPrepareStatusData(SPRConfig) {
        const statusData = SPRConfig.textEmbeddingStatus;
        const state = statusData?.finishedAt
          ? SPR_PREPARE_STATES.FINISHED
          : statusData?.startedAt
          ? SPR_PREPARE_STATES.RUNNING
          : SPR_PREPARE_STATES.RAW;
        const hasError = statusData?.finishedAt ? !!statusData?.error?.code : false;
        return {
          state,
          isPrepared: statusData?.finishedAt && statusData?.resultRowCount && !hasError,
          nofProccessed: statusData?.finishedAt ? statusData?.resultRowCount : null,
          hasError,
          error: hasError ? statusData?.error : null,
          extraInfo: this.getExtraInfo(state, statusData?.startedAt, statusData?.finishedAt),
        };
      },
      buildGenerationStatusData(SPRConfig) {
        const statusData = SPRConfig.generationStatus;
        const state = !statusData
          ? SPR_PREPARE_STATES.RAW
          : statusData?.finishedAt
          ? SPR_PREPARE_STATES.FINISHED
          : statusData?.startedAt
          ? SPR_PREPARE_STATES.RUNNING
          : SPR_PREPARE_STATES.RAW;
        const hasError = statusData?.finishedAt ? !!statusData?.error?.code : false;
        return {
          state,
          requestedAmount: statusData?.requestedAmount ?? 0,
          resultRowCount: statusData?.resultRowCount ?? 0,
          hasError,
          error: hasError ? statusData?.error : null,
          extraInfo: this.getExtraInfo(state, statusData?.startedAt, statusData?.finishedAt),
        };
      },
      isGenerateEnabled(SPRConfig) {
        const isPrepared =
          SPRConfig.textEmbeddingStatus?.finishedAt &&
          SPRConfig.textEmbeddingStatus?.resultRowCount &&
          !!SPRConfig.textEmbeddingStatus?.error;
        const isNotRunning =
          SPRConfig.generationStatus?.finishedAt || !SPRConfig.generationStatus?.startedAt;

        return isPrepared && isNotRunning;
      },
      getExtraInfo(state, startedAt, finishedAt) {
        return SPR_PREPARE_STATES.RUNNING === state
          ? this.$t('SPR.table.startedAt', {
              time: this.formatDate(startedAt, DATE_FORMAT),
            })
          : SPR_PREPARE_STATES.FINISHED === state
          ? this.$t('SPR.table.finishedAt', {
              time: this.formatDate(finishedAt, DATE_FORMAT),
            })
          : null;
      },
      generateSPR(id) {
        this.showGenerateModal(id);
      },
      deleteSPRConfig(id) {
        this.showDeleteModal(id);
      },
      showGenerateModal(id) {
        this.$modal.show('spr-generate-modal', { id });
      },
      showDeleteModal(id) {
        this.$modal.show('spr-config-delete-modal', { id });
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '../../sass/variables/_colors'

  .spr-table
    ::v-deep.brand-table

      .thead
        margin: 0

      .th,
      .td
        padding: 0 5px !important

      .thead
        .tr
          .th
            span
              padding: 20px 0
              text-transform: capitalize
              font-weight: 600
              font-size: 120%

          .text-left
            display: flex
            justify-content: flex-start
            text-align: left !important
          .text-right
            display: flex
            justify-content: flex-end !important

    ::v-deep.brand-table
      .tbody
        .brand-table-tr
          padding: 15px 0
          margin: 0
          min-height: 0

          .td
            font-weight: 400

  .warning
    color: $om-red

  .row-2
    color: $om-dark-grey
</style>
